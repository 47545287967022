import {Button, Container, Modal} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../context/AuthProvider";
import Fenomenologia from "./task/Fenomenologia";
import Hypos from "./task/Hypos";
import MainDiagnos from "./task/MainDiagnos";
import DiagSummary from "./task/DiagSummary";
import PredMerop from "./task/PredMerop";
import Katamnez from "./task/Katamnez";
import FinalCheckDiagnos from "./task/FinalCheckDiagnos";
import PsyDeterm from "./task/PsyDeterm";
import log from "../Logger";
import PsyProblem from "./task/psyProblem/PsyProblem";
import axios from "axios";
import {SERVER_URL} from "../Config";

const ChapterSelect = () => {
    const {auth, setTask, task, problems, timeToSkip, commercial, wrongAnswersToSkip } = useContext(AuthContext);

    const [show, setShow] = useState(false);

    const [showCanSkipOnlyOnce, setShowCanSkipOnlyOnce] = useState(false);
    const [showCanSkip, setShowCanSkip] = useState(false);

    log('open task: (' + JSON.stringify(task)  + ' )');

    const [goToNext, setGoToNext] = useState(false);

    useEffect(()=>{
        if (commercial) {
            return;
        }

        const timer = setTimeout(()=>setGoToNext(true), timeToSkip);
        return () => {
            clearTimeout(timer);
        }
    }, [task.page]);

    useEffect( ()=>{
        if (!showCanSkipOnlyOnce && task.wrongAnswers >= wrongAnswersToSkip) {
            setShowCanSkipOnlyOnce(true);
            setShowCanSkip(true);
        };
    },[task.wrongAnswers]);

    return (
        <Container className={'mt-5 row'}>
            <Container>
                <div className={'row my-4'}>
                    <div className={'lead text-end col-sm-1'}>{task.page !== 0 ? task.page: ''}.</div>
                    <div className={'lead text-start col-sm-3'}></div>
                    <div className={'lead text-start col-sm-8'}>
                        <img src={problems[task.page - 1].determination_url} alt={problems[task.page - 1].title}/>
                    </div>
                </div>
            </Container>

            {
                task && task.chapter === 0 || !task.chapter ?
                    <Container>
                        <Container className={'my-3'}>
                            &nbsp;
                        </Container>
                        {
                            task.checkFinalDiagnos ?
                                <>
                                    <FinalCheckDiagnos></FinalCheckDiagnos>
                                    { task.openResult ==="fullRight" ? <>
                                            <Container className={'mt-1'}>
                                                <Button onClick={() => setTask({...task, number: 1, chapter: 10, checkFinalDiagnos: false})}s>Далее</Button>
                                            </Container>
                                            <Container>&nbsp;</Container>
                                            <Container>&nbsp;</Container>
                                            <Container>&nbsp;</Container>
                                        </> :  <>
                                        <Container className={'mt-5'}>
                                            <Container className={'mt-3'}>
                                                <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 1, checkFinalDiagnos: false})}>Феноменология</Button>
                                                {/* <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 8, checkFinalDiagnos: false})}>Психологическая проблема</Button> */}
                                                <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 2, checkFinalDiagnos: false})}>Гипотезы</Button>
                                            </Container>
                                            <Container>
                                                <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 3, checkFinalDiagnos: false})}>Проверка гипотез</Button>
                                                {/* <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 3, checkFinalDiagnos: false})}>Данные психологического обследования</Button> */}
                                                <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 4, checkFinalDiagnos: false})}>Диагноз заключение</Button>
                                            </Container>
                                            <Container className={'mt-2 text-success '}>
                                                <Button className={'m-2'} onClick={() => setShow(true)}>Результат</Button>
                                            </Container>
                                        </Container>
                                        </>
                                    }
                                </>
                                :
                                <>
                                <Container>
                                    <Container>
                                        <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 1, checkFinalDiagnos: false})}>Феноменология</Button>
                                        {/* <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 8, checkFinalDiagnos: false})}>Психологическая проблема</Button> */}
                                        <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 2, checkFinalDiagnos: false})}>Гипотезы</Button>
                                    </Container>
                                    <Container className={'mt-3'}>
                                        <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 3, checkFinalDiagnos: false})}>Проверка гипотез</Button>
                                        {/* <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 3, checkFinalDiagnos: false})}>Данные психологического обследования</Button> */}
                                        <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 4, checkFinalDiagnos: false})}>Диагноз</Button>
                                    </Container>
                                </Container>
                                    <Container className={'mt-2 text-success '}>
                                        <Button className={'m-2'} onClick={() => setShow(true)}>Результат</Button>
                                    </Container>
                                    <Container>

                                    </Container>
                                </>
                        }
                    </Container>: ''
            }
            {
                task && task.chapter === 1 ? <Fenomenologia></Fenomenologia> : ''
            }
            {
                task && task.chapter === 2 ? <Hypos></Hypos> : ''
            }
            {
                task && task.chapter === 3 ? <MainDiagnos></MainDiagnos> : ''
            }
            {
                task && task.chapter === 4 ? <DiagSummary></DiagSummary> : ''
            }
            {
                task && task.chapter === 5 ? <PsyDeterm></PsyDeterm> : ''
            }
            {
                task && task.chapter === 6 ? <PredMerop></PredMerop> : ''
            }
            {
                task && task.chapter === 7 ? <Katamnez></Katamnez> : ''
            }
            {
                task && task.chapter === 8 ? <PsyProblem></PsyProblem> : ''
            }
            {
                task && task.chapter === 10 ? <>
                    <Container>
                        <Container>
                            <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 1, checkFinalDiagnos: false})}>Феноменология</Button>
                            {/* <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 8, checkFinalDiagnos: false})}>Психологическая проблема</Button> */}
                            <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 2, checkFinalDiagnos: false})}>Гипотезы</Button>
                        </Container>
                        <Container className={'mt-3'}>
                            {/* <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 3, checkFinalDiagnos: false})}>Данные психологического обследования</Button> */}
                            <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 4, checkFinalDiagnos: false})}>Диагностическое заключение</Button>
                        </Container>
                    </Container>
                    <Container className={'mt-3 text-success '}>
                        <h3 className={'mt-3'}>Теперь вам доступны</h3>
                        {/* <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 5})}>Схема психологической детерминации</Button> */}
                        {/* <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 6})}>Предложенные мероприятия</Button> */}
                        <Button className={'m-2'} onClick={() => setTask({...task, number: 1, chapter: 7})}>Результат</Button>
                    </Container>
                     <Container className={'mt-1'}>
                        <Button onClick={() => {
                            let newPage = task.page + 1;
                            if (newPage > problems.length) {
                                newPage = task.page;
                                setTask({page: newPage, id:problems[newPage - 1].id, lastTask: newPage, number: 1, chapter: 0, wrongAnswers:0, checkFinalDiagnos: false, lessonDone: true})
                                return;
                            }
                            setTask({page: newPage, id:problems[newPage - 1].id, lastTask: newPage, number: 1, chapter: 0, wrongAnswers:0, checkFinalDiagnos: false});
                        }}>К следующей задаче</Button>
                    </Container>
                </> : ''
            }
            {task && (goToNext || task.wrongAnswers >= wrongAnswersToSkip ) && <Container className={'mt-5'}>
                    <Button onClick={() => {
                        let newPage = task.page + 1;
                        if (newPage > problems.length) {
                            newPage = task.page;
                            setTask({page: newPage, id:problems[newPage - 1].id, lastTask: newPage, number: 1, chapter: 0, type: 0, wrongAnswers:0, checkFinalDiagnos: false, lessonDone: true})
                            return;
                        }
                        setGoToNext(false);
                        setTask({page: newPage, id:problems[newPage - 1].id, lastTask: newPage, number: 1, chapter: 0, type: 0, wrongAnswers:0, checkFinalDiagnos: false})
                    }}>К следующей задаче</Button>
                </Container>
            }

            <Modal
                show={show}
                onHide={() => {setShow(false);}}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Вы не решили задачу</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'text-center'}>
                    Доступно будет только после правильного решения задачи.
                </Modal.Body>

            </Modal>

            <Modal
                show={showCanSkip}
                onHide={() => {setShowCanSkip(false);}}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Вы не решили задачу</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'text-center'}>
                    С этого момента вы можете пропустить эту задачу
                </Modal.Body>
                <Modal.Footer>
                    <Container className={'text-center'}>
                        <Button onClick={() => {setShowCanSkip(false);}}>Принято</Button>
                    </Container>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default ChapterSelect
